<template>
  <v-container fluid>
    <v-card class="mb-3" v-if="filter">
      <v-card-actions class="py-0 px-5">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field v-model="search" prepend-icon="fa-search" label="Meklēt" clearable/>
          </v-col>
          <v-col cols="12" md="3">
            <v-menu
              ref="menu1"
              v-model="calendarDialog"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Datums"
                  persistent-hint
                  prepend-icon="fa-calendar"
                  v-bind="attrs"
                  v-on="on"
                  append-icon="fa-search"
                  @click:append="filterDateSearch"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                no-title
                range
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              v-model="month"
              :items="months"
              item-text="name"
              item-value="value"
              label="Mēneši"
              @change="filterMonthSearch"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card :loading="loading">
      <v-toolbar dense flat>
        <v-toolbar-title>Budžeti - {{ monthName }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="filter = !filter">
          <v-icon>fa-sliders-h</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider/>
      <v-data-table
        ref="calendar2"
        :headers="headers"
        :items="companies"
        item-key="item"
        :search="search"
        height="700"
        fixed-header
        :footer-props="{
          'items-per-page-options': [10, 50, 100, -1]
        }"
        :items-per-page="50"
      >
        <template v-slot:item.balance="{ item }">
          <v-chip :color="level(item.balance)" text-color="white">{{ item.balance }}</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu bottom :close-on-click="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>fa-ellipsis-v</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item :to="{name: 'companyShow', params: {id: item.company_id}}">
                <v-list-item-title>Skatīt uzņemumu</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from '@/axios'

export default {
  name: 'BudgetIndex',
  data: () => ({
    filter: true,
    calendarDialog: false,
    loading: true,
    search: '',
    dates: [],
    months: [
      {
        name: 'Janvāris',
        value: 1
      },
      {
        name: 'Februāris',
        value: 2
      },
      {
        name: 'Marts',
        value: 3
      },
      {
        name: 'Aprīlis',
        value: 4
      },
      {
        name: 'Maijs',
        value: 5
      },
      {
        name: 'Jūnijs',
        value: 6
      },
      {
        name: 'Jūlijs',
        value: 7
      },
      {
        name: 'Augusts',
        value: 8
      },
      {
        name: 'Septembris',
        value: 9
      },
      {
        name: 'Oktobris',
        value: 10
      },
      {
        name: 'Novembris',
        value: 11
      },
      {
        name: 'Decembris',
        value: 12
      }
    ],
    month: '',
    monthName: '',
    companies: []
  }),
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    headers () {
      return [
        {
          text: 'Uzņēmums',
          value: 'company_name'
        },
        {
          text: 'Iztērētais budžets',
          value: 'used_budget'
        },
        {
          text: 'Mēneša budžets',
          value: 'month_budget'
        },
        {
          text: 'Atlikums',
          value: 'balance'
        },
        {
          text: 'Palaisto kampaņu sk.,',
          value: 'count_advertisements'
        },
        {
          text: 'Lead kampaņu sk., ',
          value: 'count_lead'
        },
        {
          text: 'Pēd., kampaņas diena',
          value: 'date_last_advertisements'
        },
        {
          text: 'Starta datums ',
          value: 'budget_date'
        },
        {
          text: 'Darbības',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    async fetchDataMonth () {
      await axios.get('analytics/calculating-budget')
      await axios.get('analytics/companies-month').then(response => {
        this.companies = response.data.items
        this.month = response.data.date.month_number
        this.monthName = response.data.date.month_format
        this.loading = false
      })
    },
    level (budget) {
      if (budget < 0) {
        return 'red'
      } else {
        return 'green'
      }
    },
    filterDateSearch () {
      this.loading = true
      axios.post('analytics/companies-month', {
        dates: this.dates
      }).then(response => {
        this.companies = response.data.items
        this.loading = false
      })
    },
    filterMonthSearch () {
      this.loading = true
      axios.post('analytics/companies-month', {
        month: this.month
      }).then(response => {
        this.companies = response.data.items
        this.month = response.data.date.month_number
        this.monthName = response.data.date.month_format
        this.loading = false
      })
    }
  },
  created () {
    this.fetchDataMonth()
  }
}
</script>

<style scoped>

</style>
