var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.filter)?_c('v-card',{staticClass:"mb-3"},[_c('v-card-actions',{staticClass:"py-0 px-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"prepend-icon":"fa-search","label":"Meklēt","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Datums","persistent-hint":"","prepend-icon":"fa-calendar","append-icon":"fa-search","readonly":""},on:{"click:append":_vm.filterDateSearch},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}],null,false,1965345769),model:{value:(_vm.calendarDialog),callback:function ($$v) {_vm.calendarDialog=$$v},expression:"calendarDialog"}},[_c('v-date-picker',{attrs:{"no-title":"","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.months,"item-text":"name","item-value":"value","label":"Mēneši"},on:{"change":_vm.filterMonthSearch},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}})],1)],1)],1)],1):_vm._e(),_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Budžeti - "+_vm._s(_vm.monthName))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.filter = !_vm.filter}}},[_c('v-icon',[_vm._v("fa-sliders-h")])],1)],1),_c('v-divider'),_c('v-data-table',{ref:"calendar2",attrs:{"headers":_vm.headers,"items":_vm.companies,"item-key":"item","search":_vm.search,"height":"700","fixed-header":"","footer-props":{
        'items-per-page-options': [10, 50, 100, -1]
      },"items-per-page":50},scopedSlots:_vm._u([{key:"item.balance",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.level(item.balance),"text-color":"white"}},[_vm._v(_vm._s(item.balance))])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-ellipsis-v")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{name: 'companyShow', params: {id: item.company_id}}}},[_c('v-list-item-title',[_vm._v("Skatīt uzņemumu")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }